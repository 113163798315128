exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-beauty-quiz-index-js": () => import("./../../../src/pages/beauty-quiz/index.js" /* webpackChunkName: "component---src-pages-beauty-quiz-index-js" */),
  "component---src-pages-beauty-tips-article-js": () => import("./../../../src/pages/beauty-tips/article.js" /* webpackChunkName: "component---src-pages-beauty-tips-article-js" */),
  "component---src-pages-beauty-tips-index-js": () => import("./../../../src/pages/beauty-tips/index.js" /* webpackChunkName: "component---src-pages-beauty-tips-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-up-details-js": () => import("./../../../src/pages/make-up/details.js" /* webpackChunkName: "component---src-pages-make-up-details-js" */),
  "component---src-pages-make-up-index-js": () => import("./../../../src/pages/make-up/index.js" /* webpackChunkName: "component---src-pages-make-up-index-js" */),
  "component---src-pages-makeup-quiz-index-js": () => import("./../../../src/pages/makeup-quiz/index.js" /* webpackChunkName: "component---src-pages-makeup-quiz-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-skincare-detail-js": () => import("./../../../src/pages/skincare/detail.js" /* webpackChunkName: "component---src-pages-skincare-detail-js" */),
  "component---src-pages-skincare-index-js": () => import("./../../../src/pages/skincare/index.js" /* webpackChunkName: "component---src-pages-skincare-index-js" */),
  "component---src-pages-skincare-poll-index-js": () => import("./../../../src/pages/skincare-poll/index.js" /* webpackChunkName: "component---src-pages-skincare-poll-index-js" */),
  "component---src-pages-skincare-quiz-index-js": () => import("./../../../src/pages/skincare-quiz/index.js" /* webpackChunkName: "component---src-pages-skincare-quiz-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

